import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
const ReorderFilters = [
  <TextInput
    key="full_display_name"
    label="Search"
    source="full_display_name@ilike"
    alwaysOn
  />,
  <BooleanInput source="needs_reorder" />,
]
export const ReorderList = () => {
  const [organization_id] = useOrganizationId()
  return (
    <List
      filter={{ "deleted_at@is": null, organization_id }}
      filters={ReorderFilters}
    >
      <Datagrid rowClick="show">
        <TextField source="full_display_name" />
        <ReferenceField source="project_id" reference="projects" />
        <NumberField source="quantity" />
        <NumberField source="reorder_point" />
        <BooleanField source="needs_reorder" FalseIcon={null} />
        <NumberField source="reordered_quantity" />
        <DateField source="reordered_at" />
      </Datagrid>
    </List>
  )
}
