import { SimpleForm } from "@react-admin/ra-rbac"
import { AsYouType, isValidNumber } from "libphonenumber-js"
import { useMemo } from "react"
import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { useRoles } from "../roles"

export function CrewMemberForm(props) {
  const [organization_id] = useOrganizationId()
  const filter = useMemo(() => {
    return {
      organization_id,
      "deleted_at@is": "NULL",
    }
  }, [organization_id])
  return (
    <SimpleForm defaultValues={{ organization_id }} {...props}>
      <TextInput source="name" />
      <TextInput
        source="phone_number"
        type="tel"
        parse={(value) => {
          const formatter = new AsYouType("US")
          formatter.input(value)
          const phoneNumber = formatter.getNumber()?.format("E.164") || value
          return phoneNumber
        }}
        validate={[
          (value) => {
            return value == null || value === "" || isValidNumber(value)
              ? undefined
              : "Invalid phone number"
          },
        ]}
      />
      <TextInput
        source="email"
        type="email"
        parse={(value) => value.trim().toLowerCase()}
      />
      <BooleanInput
        source="enable_tool_add_notifications"
        defaultValue={false}
      />
      <RoleInput required />
      <ReferenceInput
        reference="projects"
        source="default_project_id"
        filter={filter}
      >
        <AutocompleteInput
          source="project_name"
          optionText="project_name"
          filterToQuery={(query) => ({ "name@ilike": query })}
        />
      </ReferenceInput>
    </SimpleForm>
  )
}

export function RoleInput(props) {
  const roles = useRoles()
  return (
    <SelectInput
      {...props}
      defaultValue="crew"
      source="role"
      emptyText="Select a role"
      choices={roles}
    />
  )
}
