import { Datagrid, List } from "@react-admin/ra-rbac"
import {
  ArrayField,
  BooleanField,
  FileField,
  TextField,
  TextInput,
} from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { ServiceIntervalField } from "../../Components/ServiceIntervalField"

export const ServiceTemplateList = () => {
  const [organization_id] = useOrganizationId()
  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      filter={{ "deleted_at@is": null, organization_id }}
      filters={[<TextInput source="name@ilike" alwaysOn />]}
      // perPage={25}
      // pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
      // }
    >
      <Datagrid rowClick="edit">
        {/* <ReferenceField
          source="service_template_id"
          reference="service_templates"
        /> */}
        <TextField source="name" />
        <TextField source="description" />
        <ServiceIntervalField source="service_interval" />
        <BooleanField source="is_recurring" />
        <ArrayField source="checklist" />
        <FileField
          label="resources.service_templates.fields.attachment"
          source="attachment.src"
          target="_blank"
          title="attachment.title"
        />
        {/* <ReferenceManyToManyField
          reference="tools"
          through="tool_services"
          using="service_template_id,tool_id"
          label="resources.service_templates.fields.tools"
        >
          <SingleFieldList>
            <ChipField source="display_name" />
          </SingleFieldList>
        </ReferenceManyToManyField> */}
      </Datagrid>
    </List>
  )
}
