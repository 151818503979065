import polyglotI18nProvider from "ra-i18n-polyglot"
import englishMessages from "ra-language-english"
import { mergeTranslations } from "react-admin"
// import frenchMessages from "ra-language-french"
import { raAuditLogLanguageEnglish } from "@react-admin/ra-audit-log"
import { raSupabaseEnglishMessages } from "ra-supabase-language-english"

export const i18nProvider = polyglotI18nProvider(() =>
  mergeTranslations(
    englishMessages,
    raAuditLogLanguageEnglish,
    raSupabaseEnglishMessages,
    {
      // Add your custom messages here
      ra: {
        auth: {
          user_not_found:
            "User not found, visit https://www.tooltribe.com to sign up or contact support@tooltribe.com for assistance",
        },
        action: {
          procore: {
            projects: {
              import: {
                button: "Import Projects from Procore",
                selected: "Import Selected Projects",
                notification:
                  "Imported %{smart_count} Procore project |||| Imported %{smart_count} Procore projects",
              },
            },
            users: {
              import: {
                button: "Import Crew Members from Procore",
                selected: "Import Selected Crew Members",
                notification:
                  "Imported %{smart_count} crew member From Procore  |||| Imported %{smart_count} crew members from Procore",
              },
            },
            cost_codes: {
              import: {
                button: "Import Cost Codes from Procore",
                selected: "Import Selected Cost Codes",
                notification:
                  "Imported %{smart_count} Procore cost code |||| Imported %{smart_count} Procore cost codes",
              },
            },
          },
          clear_state: "Clear Status",
          inactive_status: "Set Status",
          active: "Clear Audit/Transfer/Request",
          audit: "Audit",
          transfer: "Transfer",
          mark_single_repair: "Mark Single Item Out for Repair",
          magic_link_sent: "Check your email for the magic link",
        },
      },
      // TODO: File a bug on this as we should be able to override the key
      "ra-form-layout": {
        action: { bulk_update: "Update Items" },
      },
      "ra-audit-log": {
        record_event: {
          insert: "Created",
          tool: {
            create: "Created and assigned to %{sender}",
          },
          transfer: {
            cancelled: "Cancelled %{action} from %{sender}",
            accepted: "%{recipient} accepted transfer from %{sender}",
            auditing: "Requested an audit from %{sender}",
            audited: "Audit completed by %{sender}",
            inactive: "Transfer inactive",
            requested: "Requested transfer from %{sender} to %{recipient}",
            transferring: "Transferring from %{sender} to %{recipient}",
            change_project: "Assigned to project %{project}",
            transferred: "Transferred from %{sender} to %{recipient}",
          },
          legacy_transfer: {
            add_tool: "Assigned to %{recipient}",
            transfer: "Transferred from %{sender} to %{recipient}",
          },
        },
      },
      resources: {
        tools: {
          name: "Item |||| Items",
          fields: {
            item_id: "Item ID",
            name: "Name",
            category: "Category",
            "category@in": "Categories",
            quantity: "Quantity",
            display_name: "Name",
            asset_tag: "Item ID",
            sender_id: "Crew Member",
            "sender_id@in": "Crew Members",
            brand: "Brand",
            "brand@in": "Brands",
            project_id: "Project",
            "project_id@in": "Projects",
            purchase_order: "Purchase Order",
            state: "Transfer State",
            description: "Description",
            value: "Unit Value",
            quantity_value: "Quantity Value",
            last_service_date: "Last Service Date",
            next_service_date: "Next Service Date",
            recipient_id: "Transferee",
            serial_number: "Serial Number",
            tool_photo: "Photo",
            primary_photo: "Photo",
            primary_photo_link: "Large Photo",
            created_at: "Added",
            updated_at: "Last Modified",
            organization_number: "Organization Number",
            is_consumable: "Consumable",
            inactive_status: "Status",
            attachment: "Attachment",
            enable_procore_daily_log: "Synch Procore Daily Logs",
          },
          actions: {
            edit_status: "Edit Status",
          },
        },
        tool_scans: {
          name: "Scan |||| Scans",
          fields: {
            tool_id: "Item",
            scan_photo: "Photo",
            created_at: "Date",
          },
        },
        crew_members: {
          name: "Crew Member |||| Crew Members",
          fields: {
            enable_tool_add_notifications:
              "Send e-mail notifications to this crew member when any tools are added to the organization",
          },
        },
        projects: {
          name: "Project |||| Projects",
        },
        brands: {
          name: "Brand |||| Brands",
        },
        categories: {
          name: "Category |||| Categories",
          fields: {
            name: "Name",
            image_url: "Image",
          },
        },
        reorders: {
          name: "Reorder Point |||| Reorder Points",
          fields: {
            group_id: "Item",
            // reorder_date: "Reorder Date",
            // reorder_status: "Reorder Status",
            // reorder_notes: "Reorder Notes",
          },
        },
        organizations: {
          name: "Organization |||| Organizations",
        },
        project_report: {
          name: "Tools",
          fields: {
            tool_id: "Item",
            projects: { project_name: "Project" },
            tools: {
              full_display_name: "Item",
              value: "Value",
              category: "Category",
            },
          },
        },
        consumables_report: {
          name: "Consumables",
          fields: {
            tool_id: "Item",
          },
        },
        service_templates: {
          name: "Service Type |||| Service Types",
          fields: {
            name: "Name",
            "name@ilike": "Name",
            description: "Description",
            attachment: "Attachment",
            tools: "Tools",
            is_recurring: "Recurring",
            service_interval: "Interval",
            after_interval: "Schedule After",
            every_interval: "Schedule Every",
          },
        },
        tool_services: {
          name: "Scheduled Service |||| Scheduled Service",
          fields: {
            service_template_id: "Service Type",
            due_date: "Due Date",
            "due_date@gte": "Due Date After",
            "due_date@lte": "Due Date Before",
            tools: {
              full_display_name: "Item",
              asset_tag: "Item ID",
              serial_number: "Serial Number",
              project_id: "Project",
              projects: {
                project_name: "Project",
              },
            },
            crew_member_id: "Assigned To",
            crew_members: {
              name: "Assigned To",
            },
          },
        },
        service_records: {
          name: "Service Log |||| Service Logs",
          fields: {
            tool_service_id: "Service Type",
            notes: "Notes",
            cost: "Cost",
            attachment: "Attachment",
            checklist: "Checklist",
            crew_member_id: "Completed By",
            completed_at: "Completed At",
            "completed_at@gte": "Completed After",
            "completed_at@lte": "Completed Before",
            "cost@gte": "Cost Greater Than",
            "cost@lte": "Cost Less Than",
            tools: {
              full_display_name: "Item",
              asset_tag: "Item ID",
            },
          },
        },
        "procore/companies": {
          name: "Procore Company |||| Procore Companies",
          fields: {
            name: "Name",
            logo_url: "Logo",
          },
        },
        "procore/projects": {
          name: "Procore Project |||| Procore Projects",
          fields: {
            display_name: "Procore Project Name",
          },
        },
        "procore/people": {
          name: "Procore Person |||| Procore People",
          fields: {
            employee_id: "Employee ID",
            first_name: "First Name",
            last_name: "Last Name",
            email: "Email",
            phone_number: "Phone Number",
            is_employee: "Employee",
          },
          filters: {
            search: "Search",
            is_employee: "Employees",
          },
        },
        "procore/users": {
          name: "Procore User |||| Procore Users",
          fields: {
            employee_id: "Employee ID",
            name: "Name",
            email_address: "Email",
            mobile_phone: "Mobile Phone",
            avatar: "Profile Picture",
            is_employee: "Employee",
          },
          filters: {
            search: "Search",
            is_employee: "Employees",
          },
        },
        "procore/cost_codes": {
          name: "Procore Cost Code |||| Procore Cost Codes",
          fields: {
            full_code: "Code",
            name: "Name",
          },
        },
      },
    }
  )
)
