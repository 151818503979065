import { Edit, SimpleForm, SimpleShowLayout } from "@react-admin/ra-rbac"
import { BooleanInput, ImageField, ImageInput, TextField } from "react-admin"
import { useParams } from "react-router-dom"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { ThumbnailField } from "../../Components/ThumbnailField"
import { CrewMemberInput } from "../CrewMemberInput"
import { ProjectInput } from "../ProjectInput"
import { InactiveStatus } from "../inactiveStatuses"
import { ToolState } from "../toolStates"
import { ToolTransferToolbar } from "./ToolTransferToolbar"
import { TransferQuantityInput } from "./TransferQuantityInput"

export function ToolTransfer() {
  const [organization_id] = useOrganizationId()
  const { toolId } = useParams()
  return (
    <Edit
      id={toolId}
      actions={false}
      transform={(record, { previousData }) => {
        // Add confirmation here if they are transferring with confirmation.
        // TODO: consider handling this on the server side. If next_project_id or recipient_id is set on an active record, set project_id and sender_id to those values instead.
        if (record.state === ToolState.Transferring) {
          record.recipient_id = record.sender_id
          record.sender_id = previousData.sender_id
          record.next_project_id = record.project_id
          record.project_id = previousData.project_id
        } else if (record.is_consumable) {
          // TODO: Handle this server side as well, keeping in mind toolroom to toolroom transfers should not be considered consumed.
          record.state = ToolState.Inactive
          record.inactive_status = InactiveStatus.Consumed
        }
        return {
          ...record,
        }
      }}
    >
      <SimpleShowLayout>
        <ThumbnailField source="primary_photo" src="src" />
        <TextField source="display_name" label="Tool" />
        <TextField source="category" />
      </SimpleShowLayout>
      <SimpleForm toolbar={<ToolTransferToolbar />}>
        <CrewMemberInput source="sender_id" organization_id={organization_id} />
        <ProjectInput source="project_id" organization_id={organization_id} />
        <BooleanInput
          source="state"
          label="Require Confirmation"
          parse={(checked) => {
            if (checked) {
              return ToolState.Transferring
            }
            return ToolState.Active
          }}
          format={(value) => value === ToolState.Transferring}
        />
        <TransferQuantityInput source="quantity" />
        <ImageInput source="transfer_photo">
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}
