import { FunctionField } from "react-admin"

export function PercentField(props) {
  if (!props.record) return null
  if (!props.record[props.source]) return null
  return (
    <FunctionField
      {...props}
      render={(record) => `${record[props.source] * 100}%`}
    />
  )
}
