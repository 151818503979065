import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { InputHelperText, useInput, useTranslate } from "react-admin"
import { useWatch } from "react-hook-form"

export function ServiceIntervalInput(props) {
  const is_recurring = useWatch({ name: "is_recurring" })
  const { onChange, onBlur, helperText, ...rest } = props
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState: { invalid, error },
    isRequired,
  } = useInput({
    // Pass the event handlers to the hook but not the component as the field property already has them.
    // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
    onChange,
    onBlur,
    ...rest,
  })
  const translate = useTranslate()
  const label = translate(
    `resources.service_templates.fields.${
      is_recurring ? "every_interval" : "after_interval"
    }`
  )
  const [textValue, selectValue] = value.split(" ")
  const textFieldOnChange = (e) => {
    fieldOnChange(`${e.target.value} ${selectValue}`)
  }
  const selectOnChange = (e) => {
    fieldOnChange(`${textValue} ${e.target.value}`)
  }

  return (
    <div style={{ display: "flex", gap: 10 }}>
      <TextField
        {...field}
        value={textValue}
        type="number"
        onChange={textFieldOnChange}
        label={label}
        error={invalid}
        helperText={
          helperText !== false || invalid ? (
            <InputHelperText
              error={error?.message}
              helperText={helperText}
              touched={false}
            />
          ) : (
            ""
          )
        }
        required={isRequired}
        {...rest}
      />
      <Select
        label="Unit"
        {...field}
        value={selectValue}
        onChange={selectOnChange}
        style={{ width: 100, height: 56 }}
      >
        <MenuItem value="days">Days</MenuItem>
        <MenuItem value="weeks">Weeks</MenuItem>
        <MenuItem value="mons">Months</MenuItem>
        <MenuItem value="years">Years</MenuItem>
      </Select>
    </div>
  )
}
